.main-container {
    margin-top: 100px;
}

.fa-microphone {
    color: #14A76C;
}

.fa-microphone:hover {
    color: #0c6e47;
}

.fa-file-audio {
    color: #FF652F;
}

.fa-file-audio:hover {
    color: #d44916;
}

.output-display {
    background-color: #383b4b;
    height: 200px;
}

.fas, .fa {
    cursor: pointer;
}

.background {
    background-color: #272727;
    color: #747474;
}